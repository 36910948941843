import React, { useEffect, useRef, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Helmet from 'react-helmet'
import { useLocation, useParams, withRouter } from 'react-router-dom'

import Slide from '@material-ui/core/Slide'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { Formik } from 'formik'
import get from 'lodash/fp/get'
import { set } from 'object-path-immutable'

import CaseSchema from '../CaseSchema'
import FAIcon from '../components/FAIcon'
import AttorneyForm from '../components/IntakeForms/AttorneyForm'
import CaseManagerForm from '../components/IntakeForms/CaseManagerForm'
import CompleteForm from '../components/IntakeForms/CompleteForm'
import InjuryForm from '../components/IntakeForms/InjuryForm'
import IntakeFormsvalidationSchemas from '../components/IntakeForms/IntakeFormsvalidationSchemas'
import ReferralForm from '../components/IntakeForms/ReferralForm'
import NewAdjusterModal from '../components/modals/NewAdjusterModal'
import NewCompanyModal from '../components/modals/NewCompanyModal'
import NavigationBar from '../components/NavigationBar'
import AdjustersService from '../services/AdjustersService'
import CasesService from '../services/CasesService'
import ReferralSourcesService from '../services/ReferralSourcesService'
import UserService from '../services/UserService'

import './IntakeForm.scss'

const steps = ['Referral', 'Attorney', 'Injury', 'Case Manager', 'Complete']

function setDefaultsOnCase(caseModel) {
  let outputModel = caseModel

  CaseSchema.forEach(({ name, value }) => {
    if (get(name, outputModel) === undefined) {
      outputModel = set(outputModel, name, value !== undefined ? value : '')
    }
  })

  return outputModel
}

const IntakeForm = withRouter(({ history }) => {
  const { id } = useParams()
  const showModel = new URLSearchParams(useLocation().search).get('showModel')
  const showValidations = new URLSearchParams(useLocation().search).get('showValidations')

  const [initialCaseModel, setInitialCaseModel] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [activeStep, setActiveStep] = useState(0)
  const [referralSources, setReferralSources] = useState([])
  const [adjusters, setAdjusters] = useState([])
  const [users, setUsers] = useState([])
  const formRef = useRef(null)
  const isDirtyCheckRef = useRef()
  const stepSchema = IntakeFormsvalidationSchemas.getForStep(activeStep)
  const [showNewReferralSourceModal, setShowNewReferralSourceModal] = useState(false)
  const [showNewAdjusterModal, setShowNewAdjusterModal] = useState(false)
  const [files, setFiles] = useState([])
  const [selectedAdjuster, setSelectedAdjuster] = useState({})
  const [selectedReferralSource, setSelectedReferralSource] = useState({})

  useEffect(() => {
    const usersPromise = UserService.getCaseManagers().then(r => {
      setUsers(r)
    })

    const referalSourcesPromise = ReferralSourcesService.getAll().then(r => {
      setReferralSources(sortByName(r))
    })

    const adjustersPromise = AdjustersService.getAll().then(r => {
      setAdjusters(sortByName(r))
    })

    const casePromise = (() => {
      setInitialCaseModel(setDefaultsOnCase({}))

      if (id) {
        return CasesService.get(id).then(r => {
          setInitialCaseModel(setDefaultsOnCase(r))
        })
      }

      return Promise.resolve({})
    })()

    const filesPromise = (() => {
      if (id) {
        return CasesService.getFiles(id).then(files =>
          setFiles(
            files.map(x => {
              x.hasBeenSaved = true
              return x
            }),
          ),
        )
      }
    })()

    // wait for everything to be done before we clear the loading indicator.
    Promise.all([usersPromise, referalSourcesPromise, casePromise, adjustersPromise, filesPromise])
      .then(() => {
        setIsLoading(false)
      })
      .catch(err => {
        setError(err.message)
        setIsLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  const onSubmit = caseModel => {
    ;(() => {
      // Remove the alternate contact fields if the switch was not toggled
      checkForAlternateContact(caseModel)

      // Updates the case model to reflect latest additions entered by user.
      setInitialCaseModel(caseModel)
      setIsLoading(true)

      const caseModelToSave = {
        ...caseModel,

        // remove these because they are not part of the saved case model
        tasks: undefined,
        billableDate: undefined,
        billableTime: undefined,
      }

      // Update or create the case if it doesn't already exist
      if (id) {
        return CasesService.update(caseModelToSave)
      } else {
        return CasesService.create(caseModelToSave)
      }
    })()
      .onSuccess(savedCase => {
        if (!id) {
          history.push(`/case/intake/${savedCase.caseNumber}`)
        }

        setError(null)

        // mark files as now saved
        setFiles(files.map(x => ({ ...x, hasBeenSaved: true })))

        if (savedCase) {
          setInitialCaseModel({
            ...savedCase,

            // add these back in
            tasks: caseModel.tasks,
            billableDate: caseModel.billableDate,
            billableTime: caseModel.billableTime,
          })
        }

        if (activeStep !== steps.length - 1) {
          setActiveStep(activeStep + 1)
        } else {
          return CasesService.completeIntake(
            caseModel.caseNumber,
            caseModel.billableDate,
            caseModel.billableTime,
            caseModel.tasks,
          )
            .onSuccess(() => {
              history.push(`/cases/${savedCase.caseNumber}`)
            })
            .onBadRequest(setError)
            .catch(() => setError('An unexpected error occurred while trying to complete intake'))
        }
      })
      .onBadRequest(err => {
        setError(err)
      })
      .catch(() => {
        setError('An unexpected error occurred while trying to save the case')
      })
      .then(() => {
        setIsLoading(false)
      })
  }

  const handleClickBack = e => {
    e.preventDefault()
    if (activeStep === 0) {
      // do nothing
    } else {
      setActiveStep(activeStep - 1)
    }
  }

  const handleClickRight = e => {
    e.preventDefault()
    formRef.current.submitForm()
  }

  const sortByName = source => {
    function compare(a, b) {
      const aName = a.name.toLowerCase()
      const bName = b.name.toLowerCase()
      if (aName < bName) {
        return -1
      }
      if (aName > bName) {
        return 1
      }
      return 0
    }

    return Array.from(source).sort(compare)
  }

  const checkForAlternateContact = caseModel => {
    // If alternate contact isn't toggled,
    // then clear out the alternate contact values
    if (!get('client.hasAlternateContact', caseModel)) {
      return {
        ...caseModel,
        client: {
          ...caseModel.client,
          alternateContact: {
            firstName: '',
            lastName: '',
            phone: '',
            fax: '',
            email: '',
          },
        },
      }
    }
  }

  const handleNewCaseFileAttached = file => {
    const newFiles = files.concat(file)
    setFiles(newFiles)
  }

  const isLoadedContent = (
    <>
      {error && (
        <Alert variant="danger">
          <strong>Error:</strong> <pre>{error}</pre>
        </Alert>
      )}
      <Formik
        initialValues={initialCaseModel}
        validationSchema={stepSchema}
        onSubmit={onSubmit}
        validateOnChange={true}
      >
        {({ dirty, values, errors, handleSubmit, handleReset, submitForm, setFieldValue, handleChange }) => {
          const handleReferralSourceCreated = r => {
            const index = referralSources.findIndex(source => source.id === r.id)

            if (index !== null) {
              const newReferralSourcesArray = referralSources.slice()
              newReferralSourcesArray.splice(index, 1, r)

              setReferralSources(sortByName(newReferralSourcesArray))
            } else {
              setReferralSources(sortByName(referralSources.concat(r)))
            }

            setFieldValue('referral.sourceId', r.id)
            setSelectedReferralSource({})
            setShowNewReferralSourceModal(false)
          }

          const handleAdjusterCreated = r => {
            const index = adjusters.findIndex(source => source.id === r.id)

            if (index !== null) {
              const newAdjustersArray = adjusters.slice()
              newAdjustersArray.splice(index, 1, r)

              setAdjusters(sortByName(newAdjustersArray))
            } else {
              setAdjusters(sortByName(adjusters.concat(r)))
            }

            setFieldValue('referral.adjusterId', r.id)
            setSelectedAdjuster({})
            setShowNewAdjusterModal(false)
          }

          function showAdjusterEditScreen(adjusterId) {
            if (adjusters) {
              const adjuster = adjusters.find(adjuster => adjuster.id === adjusterId) || {}
              setSelectedAdjuster(adjuster)
              setShowNewAdjusterModal(true)
            }
          }

          function showReferralSourcerEditScreen(referralSourceId) {
            if (referralSources) {
              const source = referralSources.find(source => source.id === referralSourceId) || {}
              setSelectedReferralSource(source)
              setShowNewReferralSourceModal(true)
            }
          }

          isDirtyCheckRef.current = () => dirty

          formRef.current = { submitForm }

          return (
            <>
              <form className="form-horizontal intake-form" onSubmit={handleSubmit} onReset={handleReset}>
                {(() => {
                  switch (activeStep) {
                    case 0:
                      return (
                        <ReferralForm
                          caseModel={values}
                          referralSources={referralSources}
                          onAddNewReferralSource={() => setShowNewReferralSourceModal(true)}
                          adjusters={adjusters}
                          onAddNewAdjuster={() => setShowNewAdjusterModal(true)}
                          onEditAdjuster={showAdjusterEditScreen}
                          onEditReferralSource={showReferralSourcerEditScreen}
                          handleFieldChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      )
                    case 1:
                      return (
                        <AttorneyForm
                          caseModel={values}
                          handleFieldChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      )
                    case 2:
                      return (
                        <InjuryForm
                          caseModel={values}
                          files={files}
                          handleNewCaseFileAttached={handleNewCaseFileAttached}
                          caseId={id}
                        />
                      )
                    case 3:
                      return <CaseManagerForm caseModel={values} setFieldValue={setFieldValue} caseManagers={users} />
                    case 4:
                      return <CompleteForm caseModel={values} />
                    default:
                      return <ReferralForm caseModel={values} />
                  }
                })()}
                <button
                  id="makeInvalidButton"
                  style={{ display: 'none' }}
                  type="button"
                  onClick={() => {
                    setFieldValue('not-a-valid-field', 'made-up-value')
                  }}
                />
              </form>
              {showNewReferralSourceModal && (
                <NewCompanyModal
                  oldModel={selectedReferralSource}
                  onSaveSuccess={handleReferralSourceCreated}
                  onClosed={() => {
                    setSelectedReferralSource({})
                    setShowNewReferralSourceModal(false)
                  }}
                />
              )}
              {showNewAdjusterModal && (
                <NewAdjusterModal
                  oldModel={selectedAdjuster}
                  onSaveSuccess={handleAdjusterCreated}
                  onClosed={() => {
                    setSelectedAdjuster({})
                    setShowNewAdjusterModal(false)
                  }}
                />
              )}
              {showModel && <pre>{JSON.stringify(values, null, 2)}</pre>}
              {showValidations && <pre>{JSON.stringify(errors, null, 2)}</pre>}
            </>
          )
        }}
      </Formik>
    </>
  )

  const handleClose = () => {
    if (isDirtyCheckRef.current()) {
      if (!window.confirm('You have unsaved changes. Are you sure you want to exit?')) return
    }

    history.push('/cases')
  }

  const navBarTitle = () => (
    <>
      <button type="button" className="btn btn-link text-primary" onClick={handleClose}>
        <FAIcon name={'times'} />
      </button>
      <span>CASE INTAKE</span>
    </>
  )

  return (
    <div>
      <Helmet bodyAttributes={{ style: 'background-color : #fff !important' }} />
      <Slide className="container-intake-form background" direction="up" in={true} mountOnEnter unmountOnExit>
        <div>
          <NavigationBar
            title={navBarTitle()}
            rightBtnText={activeStep === steps.length - 1 ? 'COMPLETE' : 'SAVE & CONTINUE'}
            leftBtnText={
              activeStep > 0 ? (
                <span>
                  <FAIcon name={'chevron-left'} /> BACK
                </span>
              ) : null
            }
            handleClickLeft={handleClickBack}
            handleClickRight={handleClickRight}
          />
          <Stepper activeStep={activeStep}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>
                  <p className="step-text">{label.toUpperCase()}</p>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className="form-content">
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              isLoadedContent
            )}
          </div>
        </div>
      </Slide>
    </div>
  )
})

export default IntakeForm
