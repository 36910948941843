import React from 'react'

import { Field } from 'formik'

export default props => (
  <Field {...props}>
    {({ field, form }) => {
      const { name, value } = field

      const handleOnChange = event => {
        if (event.target.value === '') {
          form.setFieldValue(name, null)
        } else {
          const valueToUse = event.target.value
          form.setFieldValue(name, parseFloat(valueToUse))
        }
      }

      let valueAsNumber = ''
      switch (value) {
        case null:
        case undefined:
        case '':
          break

        default:
          valueAsNumber = parseFloat(value)
      }

      return (
        <React.Fragment>
          <div className="input-group">
            <input
              id={props.id}
              value={valueAsNumber}
              className="form-control"
              type="number"
              step=".1"
              min="0"
              pattern="\\d*"
              onChange={handleOnChange}
              onBlur={form.handleBlur}
              onKeyDown={e => (e.key === 'e' || e.key === '-') && e.preventDefault()}
              aria-describedby="hours"
            />
            <div className="input-group-append">
              <span id="hours" className="input-group-text">
                hours
              </span>
            </div>
          </div>
        </React.Fragment>
      )
    }}
  </Field>
)
