import React from 'react'

import AdjusterSelecter from '../AdjusterSelecter'
import DatePicker from '../controls/DatePicker'
import { ErrorMessage, Field } from '../controls/FormikWrappers'
import ReferralSourceSelector from '../ReferralSourceSelecter'

import ReferralFormClientSection from './ReferralFormClientSection'
import ReferralFormEmployerSection from './ReferralFormEmployerSection'

import 'react-datepicker/dist/react-datepicker.css'

const ReferralForm = ({
  caseModel,
  referralSources,
  onAddNewReferralSource,
  adjusters,
  onAddNewAdjuster,
  onEditAdjuster,
  onEditReferralSource,
  handleFieldChange,
  setFieldValue,
}) => {
  return (
    <React.Fragment>
      <h4 className="form-heading mt-5">Referral</h4>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-3" htmlFor="referral.date">
              Date of Referral
            </label>
            <Field name="referral.date">
              {({ field }) => <DatePicker id="referral.date" dataFormat="YYYY-MM-DD" {...field} />}
            </Field>
            <ErrorMessage name="referral.date" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="referral.sourceId">
              Referral Source
            </label>
            <Field name="referral.sourceId">
              {({ field }) => (
                <ReferralSourceSelector
                  referralSources={referralSources}
                  onEditRequested={onEditReferralSource}
                  onAddRequested={onAddNewReferralSource}
                  id="referral.sourceId"
                  {...field}
                  isAdmin={true}
                />
              )}
            </Field>
            <ErrorMessage name="referral.sourceId" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="referral.adjusterId">
              Adjuster
            </label>
            <Field name="referral.adjusterId">
              {({ field }) => (
                <AdjusterSelecter
                  adjusters={adjusters}
                  onEditRequested={onEditAdjuster}
                  onAddRequested={onAddNewAdjuster}
                  id="referral.adjusterId"
                  {...field}
                  isAdmin={true}
                />
              )}
            </Field>
            <ErrorMessage name="referral.adjusterId" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="referral.claimNumber">
              Claim Number
            </label>
            <Field type="text" name="referral.claimNumber" className="form-control" id="referral.claimNumber" />
            <ErrorMessage name="referral.claimNumber" />
          </div>
        </div>
      </div>

      <ReferralFormClientSection
        caseModel={caseModel}
        handleFieldChange={handleFieldChange}
        setFieldValue={setFieldValue}
      />
      <ReferralFormEmployerSection handleFieldChange={handleFieldChange} setFieldValue={setFieldValue} />
    </React.Fragment>
  )
}

export default ReferralForm
