/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'

import AutoCompleteComponent, { getAddressDetails, getStreetAddress } from '../autocomplete/AutoComplete'
import DateOfBirthPickerField from '../controls/DateOfBirthPickerField'
import { ErrorMessage, Field } from '../controls/FormikWrappers'
import ToggleField from '../controls/ToggleField'
import StateSelector from '../StateSelector'

import 'react-datepicker/dist/react-datepicker.css'

const ReferralFormClientSection = props => {
  const { caseModel, handleFieldChange, setFieldValue } = props
  const [address, setAddress] = useState(null)

  useEffect(() => {
    const streetAddress = getStreetAddress(address)
    if (!streetAddress) {
      return
    }

    setFieldValue('client.address.line1', streetAddress)
    setFieldValue('client.address.city', getAddressDetails(address, 'locality'))
    setFieldValue('client.address.zip', getAddressDetails(address, 'postal_code'))
    setFieldValue('client.address.state', getAddressDetails(address, 'administrative_area_level_1'))
  }, [address, setFieldValue])

  return (
    <React.Fragment>
      <hr className="mt-5 mb-5" />
      <h4 className="form-heading">Claimant</h4>
      <div>
        <label className="form-label mt-3" htmlFor="client.firstName">
          Full Name
        </label>
        <div className="form-row">
          <div className="col-md-3">
            <div className="form-group">
              <Field
                type="text"
                id="client.firstName"
                className="form-control"
                placeholder="First Name"
                name="client.firstName"
              />
              <ErrorMessage name="client.firstName" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <Field
                type="text"
                id="client.lastName"
                name="client.lastName"
                className="form-control"
                placeholder="Last Name"
              />
              <ErrorMessage name="client.lastName" />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.address.line1">
              Address
            </label>
            <AutoCompleteComponent address={setAddress} />
            <Field
              type="text"
              name="client.address.line1"
              className="form-control"
              id="client.address.line1"
              onChange={handleFieldChange('client.address.line1')}
            />
            <ErrorMessage name="client.address.line1" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.address.city">
              City
            </label>
            <Field
              type="text"
              name="client.address.city"
              className="form-control"
              id="client.address.city"
              onChange={handleFieldChange('client.address.city')}
            />
          </div>
        </div>
        <div className="col-md-1">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.address.state">
              State
            </label>
            <Field id="client.address.state" name="client.address.state">
              {({ field }) => <StateSelector {...field} onChange={handleFieldChange('client.address.state')} />}
            </Field>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.address.zip">
              Zip Code
            </label>
            <Field
              type="text"
              name="client.address.zip"
              className="form-control"
              id="client.address.zip"
              onChange={handleFieldChange('client.address.zip')}
            />
          </div>
        </div>
      </div>
      <div>
        <ErrorMessage name="client.address.city" />
        <ErrorMessage name="client.address.state" />
        <ErrorMessage name="client.address.zip" />
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.phone">
              Phone
            </label>
            <Field
              type="tel"
              name="client.phone"
              className="form-control"
              placeholder="(123) 456-7890"
              id="client.phone"
            />
            <ErrorMessage name="client.phone" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.email">
              Email
            </label>
            <Field
              name="client.email"
              className="form-control"
              type="email"
              placeholder="name@email.com"
              id="client.email"
            />
            <ErrorMessage name="client.email" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label" htmlFor="client.birthdate">
              Date of Birth
            </label>
            <DateOfBirthPickerField name="client.birthdate" id="client.birthdate" />
            <ErrorMessage name="client.birthdate" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <h5 className="mt-2 mr-3 ml-1">Alternate Contact</h5>
        <ToggleField name="client.hasAlternateContact" id="alternateContactSwitch" />
      </div>
      {caseModel.client.hasAlternateContact && (
        <React.Fragment>
          <div>
            <label className="form-label mt-3" htmlFor="alternateContactFirstName">
              Full Name
            </label>
            <div className="form-row">
              <div className="col-md-3">
                <div className="form-group">
                  <Field
                    id="client.alternateContact.firstName"
                    className="form-control"
                    placeholder="First Name"
                    name="client.alternateContact.firstName"
                  />
                  <ErrorMessage name="client.alternateContact.firstName" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <Field
                    id="client.alternateContact.lastName"
                    className="form-control"
                    placeholder="Last Name"
                    name="client.alternateContact.lastName"
                  />
                  <ErrorMessage name="client.alternateContact.lastName" />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label mt-2" htmlFor="client.alternateContact.phone">
                    Phone
                  </label>
                  <Field
                    className="form-control"
                    type="tel"
                    placeholder="(123) 456-7890"
                    id="client.alternateContact.phone"
                    name="client.alternateContact.phone"
                  />
                  <ErrorMessage name="client.alternateContact.phone" />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-3">
                <label className="form-label mt-2" htmlFor="client.alternateContact.email">
                  Email
                </label>
                <Field
                  className="form-control"
                  type="email"
                  placeholder="name@email.com"
                  id="client.alternateContact.email"
                  name="client.alternateContact.email"
                />
                <ErrorMessage name="client.alternateContact.email" />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default ReferralFormClientSection
