import * as yup from 'yup'

import { MAX_FIELD_LENGTH, SUGGESTED_FIELD_LENGTH } from '../../utils/Constants'
import validation from '../../validation'

const phonePattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
const zipcodePattern = /^[0-9]{5}(?:-[0-9]{4})?$/
const datePattern = /\d{1,2}\/\d{1,2}\/\d{4}/

const getAddressShape = (name, required) => {
  const isRequired = x => {
    if (required) return x.required()
    return x.nullable()
  }

  return yup.object().shape({
    line1: isRequired(yup.string())
      .label(`${name} line 1`)
      .transform(value => (value === '' ? null : value))
      .max(SUGGESTED_FIELD_LENGTH),
    city: isRequired(yup.string())
      .label(`${name} city`)
      .transform(value => (value === '' ? null : value))
      .max(SUGGESTED_FIELD_LENGTH),
    state: isRequired(yup.string())
      .label(`${name} state`)
      .transform(value => (value === '' ? null : value)),
    zip: isRequired(yup.string())
      .label(`${name} zip`)
      .transform(value => (value === '' ? null : value))
      .matches(zipcodePattern, `${name} zip must be formatted as "#####" or "#####-####"`),
  })
}

const schemas = {}
schemas[0] = yup.object().shape({
  referral: yup.object().shape({
    date: yup.string().label('Date of referral').required(),
    sourceId: yup.number().label('Referral Source').required(),
    adjusterId: yup.number().label('Adjuster').required(),
    claimNumber: validation.shortText('Claim Number'),
  }),
  client: yup.object().shape({
    firstName: yup.string().label('Client first name').required().max(SUGGESTED_FIELD_LENGTH),
    lastName: yup.string().label('Client last name').required().max(SUGGESTED_FIELD_LENGTH),
    phone: yup
      .string()
      .label('Client phone')
      .required()
      .matches(phonePattern, 'Client phone number must be in format ###-###-####'),
    fax: yup
      .string()
      .label('Client fax')
      .transform(value => (value === '' ? null : value))
      .nullable()
      .matches(phonePattern, 'Client fax number must be in format ###-###-####'),
    email: yup.string().label('Client email').required().email('Client email address must be valid'),
    birthdate: yup
      .string()
      .label('Client date of birth')
      .matches(datePattern, 'Date of Birth must be in format ##/##/####')
      .transform(value => (value === '' ? null : value))
      .nullable()
      .test('is-valid-dob', 'Date of Birth is not a valid date', value => {
        if (!value) return true
        return !isNaN(new Date(value).getTime())
      })
      .test('is-valid-dob', 'Date of Birth must be on or after 1/1/1900', value => {
        if (!value) return true
        return new Date('1/1/1900') <= new Date(value)
      })
      .test('is-valid-dob', 'Date of Birth cannot be in the future', value => {
        if (!value) return true
        return new Date() > new Date(value)
      }),
    address: getAddressShape('Client address', false),
    alternateContact: yup.object().shape({
      firstName: yup.string().label('Alternate contact first name').nullable().max(SUGGESTED_FIELD_LENGTH),
      lastName: yup.string().label('Alternate contact last name').nullable().max(SUGGESTED_FIELD_LENGTH),
      phone: yup
        .string()
        .label('Alternate Contact phone')
        .transform(value => (value === '' ? null : value))
        .nullable()
        .matches(phonePattern, 'Alternate Contact phone number must be in format ###-###-####'),
      fax: yup
        .string()
        .label('Alternate Contact fax')
        .transform(value => (value === '' ? null : value))
        .nullable()
        .matches(phonePattern, 'Alternate Contact fax number must be in format ###-###-####'),
      email: yup
        .string()
        .label('Alternate Contact email')
        .transform(value => (value === '' ? null : value))
        .nullable()
        .email('Alternate Contact email address must be valid'),
    }),
  }),
  employer: yup.object().shape({
    name: yup.string().label('Employer name').required().max(SUGGESTED_FIELD_LENGTH),
    address: getAddressShape('Employer address'),
    contact: yup.object().shape({
      title: yup.string().label('Employer contact title').max(SUGGESTED_FIELD_LENGTH),
      firstName: yup.string().label('Employer contact first name').max(SUGGESTED_FIELD_LENGTH),
      lastName: yup.string().label('Employer contact last name').max(SUGGESTED_FIELD_LENGTH),
      phone: yup
        .string()
        .label('Employer contact phone')
        .nullable()
        .transform(value => (value === '' ? null : value))
        .matches(phonePattern, 'Employer company phone number must be in format ###-###-####'),
      fax: yup
        .string()
        .label('Employer contact fax')
        .nullable()
        .transform(value => (value === '' ? null : value))
        .matches(phonePattern, 'Employer contact fax number must be in format ###-###-####'),
      email: yup
        .string()
        .label('Employer contact email')
        .nullable()
        .transform(value => (value === '' ? null : value))
        .email('Employer contact email address must be valid'),
    }),
  }),
})

// attorney
schemas[1] = yup.object().shape({
  plaintiff: yup.object().shape({
    name: validation.name(),
    address: validation.address(),
    contact: validation.object({
      firstName: validation.name('First Name'),
      lastName: validation.name('Last Name'),
      phone: validation.phone(),
      fax: validation.phone('Fax'),
      email: validation.email('Email'),
    }),
  }),
  defendant: yup.object().shape({
    name: validation.name(),
    address: validation.address(),
    contact: validation.object({
      firstName: validation.name('First Name'),
      lastName: validation.name('Last Name'),
      phone: validation.phone(),
      fax: validation.phone('Fax'),
      email: validation.email('Email'),
    }),
  }),
})

// injury
schemas[2] = yup.object().shape({
  injury: yup.object().shape({
    descriptions: yup.array().of(
      yup.object().shape({
        description: yup.string().label('Injury description').required('Required').max(SUGGESTED_FIELD_LENGTH),
        provider: yup.string().label('Injury description provider').required('Required').max(SUGGESTED_FIELD_LENGTH),
      }),
    ),
    notes: yup.string().label('Injury notes').max(MAX_FIELD_LENGTH),
    date: yup.string().label('Date of injury'),
  }),
})

// complete
schemas[4] = yup.object().shape({
  caseManager: yup.string().required('Case Manager must be assigned before intake can be completed'),
  detailsForCaseManager: yup.string().label('Notes for Case Manager').max(MAX_FIELD_LENGTH),
  clientHasBeenContacted: yup.bool().label('Client has been contacted'),
  attorneyHasBeenContacted: yup.bool().label('Attorney has been contacted'),
  tasks: yup.array().of(
    yup.object().shape({
      title: yup.string().label('Task tile').required('Required').max(SUGGESTED_FIELD_LENGTH),
    }),
  ),
  appointments: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .label('Appointment title')
        .required('Required')
        .max(SUGGESTED_FIELD_LENGTH, 'Description is too long.'),
      date: yup.string().label('Appointment date').required('Required'),
    }),
  ),

  billableDate: yup.date().label('Billable Intake Date').required('Required'),

  billableTime: yup
    .number()
    .label('Billable Intake Time')
    .typeError('Time cannot be empty.')
    .required('Required')
    .test('billableTime', 'Time cannot be empty', value => {
      if (value === '') return 0
      return value
    }),
})

export default {
  getForStep: step => {
    if (schemas[step]) return schemas[step]

    return yup.object()
  },
}
