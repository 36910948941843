import { getToken } from '../utils/getToken'

import { wrapFetch } from './FetchHelper'

export default {
  closeCase,
  get,
  getFiles,
  getAll,
  create,
  update,
  getSummary,
  getInProgressReport,
  getReport,
  getReportsForCase,
  getReportsByStatus,
  completeIntake,
  submitReport,
  approveReport,
  rejectReport,
  getReportFiles,
  addMembers,
  removeMembers,
}

function handleSuccess(result) {
  if (result.ok) return result.json()

  throw new Error('Could not save case')
}

function handleFailure(defaultErrorMessage, err) {
  throw new Error(defaultErrorMessage)
}

function get(caseId, fields) {
  let url = `/api/cases/${caseId}`

  if (fields && fields.length > 0) {
    url += `?$select=${fields.join(',')}`
  }

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve case.', err))
}

function getFiles(caseNumber) {
  return wrapFetch(
    fetch(`/api/cases/${caseNumber}/files`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    }),
  ).onSuccess(json => json)
}

function closeCase(caseNumber) {
  return wrapFetch(
    fetch(`/api/Cases/${caseNumber}/close`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
      body: JSON.stringify(caseNumber),
    }),
  )
    .then(json => json)
    .catch(err => handleFailure('Unable to close case.', err))
}

function getAll(fields) {
  let url = '/api/cases'
  if (fields) {
    if (Array.isArray(fields) && fields.length > 0) {
      url += `?$select=${fields.join(',')}`
    } else {
      url += `?$select=${fields}`
    }
  }

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve cases.', err))
}

function update(caseObj) {
  return wrapFetch(
    fetch(`/api/cases/${caseObj.caseNumber}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(caseObj),
    }),
  )
}

function create(caseObj) {
  return wrapFetch(
    fetch(`/api/Cases`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(caseObj),
    }),
  )
}

function completeIntake(caseNumber, billableDate, billableTime, tasks) {
  return wrapFetch(
    fetch(`/api/cases/${caseNumber}/complete-intake`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        billableDate,
        billableTime,
        tasks,
      }),
    }),
  )
}

function getSummary(caseNumber) {
  return wrapFetch(
    fetch(`/api/cases/${caseNumber}/summary`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }),
  )
}

function getInProgressReport(caseNumber) {
  return wrapFetch(
    fetch(`/api/cases/${caseNumber}/reports/in-progress`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }),
  )
}

function getReport(caseNumber, reportNumber) {
  return wrapFetch(
    fetch(`/api/cases/${caseNumber}/reports/${reportNumber}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }),
  )
}

function getReportsForCase(caseNumber, fields) {
  let url = `/api/cases/${caseNumber}/reports`

  if (fields) {
    url += `?$select=${fields.join(',')}`
  }

  return wrapFetch(
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }),
  )
}

function getReportsByStatus(status, fields) {
  let url = `/api/reports?$filter=status+eq+"${encodeURIComponent(status)}"`

  if (fields) {
    url += `&$select=${encodeURIComponent(fields.join(','))}`
  }

  return wrapFetch(
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }),
  )
}

function getReportFiles(caseNumber, reportNumber) {
  return wrapFetch(
    fetch(`/api/cases/${caseNumber}/reports/${reportNumber}/files`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }),
  )
}

function submitReport(caseNumber) {
  return wrapFetch(
    fetch(`/api/cases/${caseNumber}/reports/submit`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }),
  )
}

function approveReport(caseNumber, reportNumber) {
  return wrapFetch(
    fetch(`/api/cases/${caseNumber}/reports/${reportNumber}/approve`, {
      method: 'POST',
    }),
  )
}

function rejectReport(caseNumber, reportNumber, rejectReason) {
  return wrapFetch(
    fetch(`/api/cases/${caseNumber}/reports/${reportNumber}/reject`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        rejectReason,
      }),
    }),
  )
}

function addMembers(caseNumber, members) {
  return fetch(`/api/cases/${caseNumber}/add-members`, {
    method: 'PUT',
    body: JSON.stringify({ members }),
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to add members to case.', err))
}

function removeMembers(caseNumber, members) {
  return fetch(`/api/cases/${caseNumber}/remove-members`, {
    method: 'PUT',
    body: JSON.stringify({ members }),
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to remove members from case.', err))
}
